body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.btn-primary {
  background-color: #a181ff !important;
  border-color: #a181ff !important;
  padding: 5px 30px !important;
}

.btn-primary:hover {
  background-color: #8e6afc !important;
}

.navbar-item {
  font-weight: bold;
}

.img-hero {
  width: 100%;
}

@media (max-width: 768px) {
  .img-hero {
    width: 40%;
  }
}
